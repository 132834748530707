import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Contact" />

    <div>
     Contact

    </div>
    
  </Layout>
)

export default IndexPage
